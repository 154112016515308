<template>
  <div class="app-box">
    <div class="search-box">
      <input type="text" placeholder="请输入机构或经销商名称" class="search-txt" v-model="keyWord" @input="inputEvent">
      <button class="cancel-btn">清除</button>
    </div>
    <x-scroll-view :top="0.52" :bottom="0.66">
      <template v-if="keyWord">
        <ul v-if="searchData.length>0" class="list-box">
          <li v-for="(item,index) in searchData" :key="index" class="list-item">
            <p class="list-item-title" @click="itemEvent(item);">{{item.orgName}}</p>
            <i class="list-item-icon" :class="{'list-item-check-icon':item.check}" @click="checkEvent(item);"></i>
          </li>
        </ul>
        <x-no-data v-if="ajaxEnd&&searchData.length>0"></x-no-data>
        <x-no-data-bg v-if="ajaxEnd&&searchData.length<=0"></x-no-data-bg>
      </template>
      <template v-else>
        <ul v-if="xhrData.length>0" class="list-box">
          <li v-for="(item,index) in xhrData" :key="index" class="list-item">
            <p class="list-item-title" @click="itemEvent(item);">{{item.orgName}}</p>
            <i class="list-item-icon" :class="{'list-item-check-icon':item.check}" @click="checkEvent(item);"></i>
          </li>
        </ul>
        <x-no-data v-if="ajaxEnd&&xhrData.length>0"></x-no-data>
        <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0"></x-no-data-bg>
      </template>
    </x-scroll-view>
    <div class="footer-box">
      <p class="all-check" :class="{'all-check-yes':isCheckAll}" @click="checkAllEvent">全选</p>
      <button class="maintain-btn" :class="{'maintain-check-btn':checkList.length>0}" @click="maintainBtn">维护({{checkList.length}})</button>
    </div>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import { queryCustomerListBySaleId } from "@/api/customerAssignment";

export default {
  data () {
    return {
      timer: null,
      keyWord: null,
      xhrData: [],
      searchData: [],
      ajaxEnd: false,
      isCheckAll: false,
      checkList: []
    };
  },
  created () {
    this.getData();
  },
  methods: {
    inputEvent () {
      this.isCheckAll = false;
      this.checkList = [];
      if (this.keyWord) {
        clearTimeout(this.timer);
        this.ajaxEnd = false;
        this.searchData = [];
        this.timer = setTimeout(() => {
          this.ajaxEnd = true;
          this.xhrData.map(item => {
            if (item.orgName.indexOf(this.keyWord) >= 0) {
              item.check = false;
              this.searchData.push(item);
            }
          });
        }, 300);
      } else {
        if (this.keyWord) {
          this.searchData.map(item => {
            item.check = false;
          });
        } else {
          this.xhrData.map(item => {
            item.check = false;
          });
        }
      }
    },
    getData () {
      queryCustomerListBySaleId({
        saleId: this.$route.params.saleId
      }).then(xhr => {
        xhr.data.map(item => {
          item["check"] = false;
          this.xhrData.push(item);
        });
        this.ajaxEnd = true;
        document.title = "";
        document.title = this.xhrData[0].maintainer;
      });
    },
    checkAllEvent () {
      if (this.isCheckAll) {
        this.isCheckAll = false;
        if (this.keyWord) {
          this.searchData.map(item => {
            item.check = false;
          });
        } else {
          this.xhrData.map(item => {
            item.check = false;
          });
        }
        this.checkList = [];
      } else {
        this.isCheckAll = true;
        if (this.keyWord) {
          this.searchData.map(item => {
            item.check = true;
          });
          this.checkList = [].concat(this.searchData);
        } else {
          this.xhrData.map(item => {
            item.check = true;
          });
          this.checkList = [].concat(this.xhrData);
        }
      }
    },
    checkEvent (item) {
      if (item.check) {
        this.checkList.map((val, index) => {
          if (val.id == item.id) {
            this.checkList.splice(index, 1);
          }
        });
        item.check = false;
      } else {
        this.checkList.push(item);
        item.check = true;
      }
      if (this.keyWord) {
        this.isCheckAll = this.checkList.length == this.searchData.length;
      } else {
        this.isCheckAll = this.checkList.length == this.xhrData.length;
      }
    },
    maintainBtn () {
      if (this.checkList.length > 0) {
        const temp = [];
        this.checkList.map(item => {
          temp.push(item.id);
        });
        this.$router.push({
          path: `/customerAssignment/batchMaintain/${temp}/${this.checkList[0].maintainer}/1`
        });
      }
    },
    itemEvent (item) {
      this.$router.push({
        path: `/customerAssignment/detail/${item.id}/${item.maintainer}/${item.orgName}`
      });
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
.list-box {
  margin: auto 0.15rem;
}
.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.18rem 0;
  line-height: 1;
  border-bottom: solid 0.01rem #f6f8fb;
}
.list-item-title {
  font-size: 0.15rem;
  color: #30353d;
}
.list-item-icon {
  width: 0.2rem;
  height: 0.2rem;
  background: url('~@images/check_no_icon.png') no-repeat center/0.18rem;
}
.list-item-check-icon {
  background: url('~@images/check_yes_icon.png') no-repeat center/0.18rem;
}
.footer-box {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 0.66rem;
  display: flex;
  align-items: center;
}
.all-check {
  margin-left: 1.04rem;
  font-size: 0.15rem;
  color: #30353d;
  padding-left: 0.24rem;
  background: url('~@images/check_no_icon.png') no-repeat left center/0.18rem;
}
.all-check-yes {
  background: url('~@images/check_yes_icon.png') no-repeat left center/0.18rem;
}
.maintain-btn {
  border: 0;
  line-height: 1;
  font-size: 0.17rem;
  color: #ffffff;
  width: 1.4rem;
  height: 0.46rem;
  border-radius: 0.24rem;
  background-color: #9fc7fc;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
  margin-left: 0.4rem;
}
.maintain-check-btn {
  background: #2b82f7;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
}
</style>
